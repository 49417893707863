// NOTE: 共通のJSとCSSがあるが、GatsbyJS HeadだとコンポーネントでHead系タグの実装ができないのでexportしてコンポーネント側で適宜読み込む仕様にした。
import React, { useEffect, useRef } from "react"

const EmbedEmbedly = ({ href, children }) => {
  const container = useRef(null)
  // @see: https://docs.embed.ly/v1.0/docs/cards
  useEffect(() => {
    const baseDom = container?.current
    if (window?.embedly && baseDom) {
      window.embedly(`card`, baseDom)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href])

  return (
    <ins
      style={{
        padding: `var(--spacing-4) var(--spacing-2)`,
        maxWidth: `500px`,
        margin: `0 auto`,
        minHeight: `250px`,
        display: `block`,
      }}
    >
      <a
        href={href}
        title="Ads"
        target="_blank"
        rel="noopener noreferrer"
        ref={container}
      >
        {children}
      </a>
    </ins>
  )
}

const EmbedEmbedlyHead = () => {
  return (
    <>
      <script
        id="embedly-js"
        src="https://cdn.embedly.com/widgets/platform.js"
        charSet="UTF-8"
        async={true}
      />
      <style id="embedly-inline-css" className="embedly-css">
        {`
body#cards .card .title {
  font-size: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
body#cards .card .description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
body#cards .card .action {
  font-size: 15px;
}
`}
      </style>
    </>
  )
}

export { EmbedEmbedly, EmbedEmbedlyHead }
