import { Link } from "gatsby"
import React from "react"

const Layout = ({ location, title, children }) => {
  // const rootPath = `${__PATH_PREFIX__}/`
  // const isRootPath = location.pathname === rootPath
  const styleHeaderLinkHome = {
    fontWeight: "var(--fontWeight-bold)",
    fontFamily: "var(--font-heading)",
    textDecoration: "none",
    fontSize: "var(--fontSize-2)",
  }
  const styleGlobalWrapper = {
    margin: "var(--spacing-0) auto",
    maxWidth: "var(--maxWidth-wrapper)",
    // padding: "var(--spacing-10) var(--spacing-4)",
  }
  const styleGlobalHeader = {
    marginBottom: "var(--spacing-4)",
    padding: `var(--spacing-8) var(--spacing-4) var(--spacing-4)`,
  }

  const header = (
    <Link style={styleHeaderLinkHome} to="/">
      {title}
    </Link>
  )

  return (
    <div style={styleGlobalWrapper}>
      <header style={styleGlobalHeader}>{header}</header>
      <main>{children}</main>

      <footer className="footer">
        <div className="footer__content">
          <span className="footer__copy">
            &copy; {new Date().getFullYear()}
            {` `}
            <Link to="/" className="footer__copy-link">
              {title}
            </Link>
          </span>
          <ul className="footer__list">
            <li className="footer__lists">
              <Link to="/privacy" className="footer__link">
                プライバシーポリシー
              </Link>
            </li>
            <li className="footer__lists">
              <Link to="/contact" className="footer__link">
                お問い合わせ
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  )
}

export default Layout
