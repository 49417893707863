import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { EmbedEmbedlyHead } from "./embed-embedly"

const Seo = ({ title, description, children }) => {
  const location = useLocation()

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  const metaDescription =
    description ||
    `Quoraで見つけた名回答をピックアップ！雑談にすぐ役立つ、知識・雑学・考え方をあなたにお届け` ||
    site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const getTitle = title ? `${title} | ${defaultTitle}` : defaultTitle
  const siteUrl = `${site?.siteMetadata?.siteUrl}${location.pathname}`

  const metaArray = [
    {
      id: `description`,
      name: `description`,
      content: metaDescription,
    },
    {
      id: `og:description`,
      property: `og:description`,
      content: metaDescription,
    },
    {
      id: `og:site_name`,
      property: `og:site_name`,
      content: defaultTitle,
    },
    {
      id: `og:url`,
      property: `og:url`,
      content: siteUrl,
    },
    {
      id: `og:type`,
      property: `og:type`,
      content: `website`,
    },
    {
      id: `twitter:card`,
      name: `twitter:card`,
      content: "summary_large_image",
    },
    {
      id: `twitter:creator`,
      name: `twitter:creator`,
      content: site.siteMetadata?.social?.twitter || ``,
    },
    {
      id: `twitter:title`,
      name: `twitter:title`,
      content: title,
    },
    {
      id: `twitter:description`,
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  return (
    <>
      <title>{getTitle}</title>
      {metaArray.map((meta, i) => {
        return (
          <meta id={meta.id} name={meta.name} content={meta.content} key={i} />
        )
      })}
      <EmbedEmbedlyHead />
      {children}
    </>
  )
}

export default Seo
